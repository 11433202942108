.graph {
  width: 100%;
  height: 100%;
  background-color: $bg-color;
}

.graph--aop {
  width: 500px;
  // width: 750px;
  max-width: 100%;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  border: solid 1px red;
}
