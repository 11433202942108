.compound-management-container {
  @extend .modal__container;

  .compound-management-content {
    @extend .modal__content;
    width: 1100px;
    min-height: 500px;
  }

  .form-actions {
    margin-top: 2rem;
  }

  .compound-list .compound {
    .compound__name {
      font-weight: bold;
    }

    .compound__smiles {
      font-size: 0.85rem;
    }
  }

  .help {
    font-size: 0.85rem;
  }
}
