.modal__container {
  width: 100vw;
  height: 100vh;
  background: rgba(220, 220, 220, .9);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal__content {
    position: relative;
    background-color: $white;
    border: solid 1px $border-color;
    box-shadow: $box-shadow;
    padding: $gap;
    flex: 0 1 auto;
    max-width: 90%;
    max-height: 90vh;
    overflow: scroll;
  }

  .close-container {
    position: absolute;
    top: $gap;
    right: $gap;
  }
}
