@import 'bulma';
@import 'graph';

@import 'components/lists';
@import 'components/modal';
@import 'components/compound-list';
@import 'components/settings-slider';
@import 'components/compound-management';
@import 'components/aop';
@import 'components/graph-info';
@import 'components/nav';
@import 'components/tippy';
@import 'components/footer';

html, body {
  height: 100%;
  margin: 0;
}

#controls {
  position: absolute;
  z-index: $z-index-controls;
  left: 0px;
  width: 100%;
}
