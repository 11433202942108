.network-settings {
  position: fixed;
  max-width: calc(100% - 30px);
  z-index: $z-index-controls;
  overflow-y: scroll;

  width: auto;
  height: auto;

  transition: all 0.5s ease;

  &.network-settings--open {
    height: 100%;
    min-width: calc(100% - 30px);

    @media (min-width: $tablet) {
      min-width: 50%;
    }

    @media (min-width: $desktop) {
      min-width: 40%;
    }

    @media (min-width: $widescreen) {
      min-width: 25%;
    }

    .settings-slide {
      left: -2px;
      height: 100%;
    }
  }

  .settings-toggler {
    position: relative;
    margin-top: $gap/2;
    margin-left: $gap/2;
    border-radius: 0;
    background-color: $info;
    color: $white;
    padding: $gap/4 $gap/2;
    display: inline-block;
    cursor: pointer;

    .fa-cog {
      margin-right: 0.4rem;
    }
  }

  .settings-slide {
    position: absolute;
    width: 100%;
    top: 0px;
    left: -200%;
    z-index: $z-index-controls;
    background: $white;
    border-right: solid 1px $border-color;
    box-shadow: $box-shadow;
    overflow: scroll;

    transition: all 0.5s ease;

    .settings-slide__header {
      padding: $gap*3/4 $gap/2 $gap*3/4 1.5rem;
      background: $info;
      color: $white;

      .settings-slide-title {
        font-weight: bold;
        text-transform: uppercase;
      }

      .close-settings-slide {
        position: absolute;
        top: 1.2rem;
        right: $gap/2;
        color: $white;
      }

      .button.is-text {
        text-decoration: none;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .settings-slide__section {
      margin: $gap*3/4 $gap/2 $gap*3/4 1.5rem;

      &:last-child {
        border: none;
      }
    }

    .settings-slide__section + .settings-slide__section {
      border-top: solid 1px $grey-lighter;
      padding-top: $gap;
    }

    .settings-slide__section-header {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: $gap/2;
    }

    .network-selection {
      float: right;
      margin-top: -0.5rem;
    }

    .menu-choices {
      margin-bottom: $gap;

      select {
        margin-bottom: 0;
      }
    }
  }

  .no-compounds {
    margin-top: $gap/2;
    font-style: italic;
    color: $text-light;
  }

  .compounds-list {
    margin-top: $gap;

    .compound {

      .compound__summary {
        display: flex;
        align-items: flex-start;
      }

      img {
        flex: 0 1 auto;
        border: solid 1px $border-color;
        margin-right: $gap/4;
      }
    }
  }

  .species-list {
    // -webkit-column-count: 2;
    // -moz-column-count: 2;
    // column-count: 2;

    .HSA, .DRE, .MMU, .RNO, .CEL {
      display: inline-block;
      margin-right: 0.3rem;
    }

    .HSA {
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: #a9a9a9;
      position: relative;
      top: -6px;
    }

    .HSA:after {
      content: '';
      position: absolute;
      left: -6px;
      top: 6px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-top-color: #a9a9a9;
    }

    .DRE {
      width: 10px;
      height: 5.5px;
      background: #a9a9a9;
      position: relative;
      margin-bottom: 3px;
    }

    .DRE:before {
      content: "";
      position: absolute;
      top: -2.5px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 2.5px solid #a9a9a9;
    }

    .DRE:after {
      content: "";
      position: absolute;
      bottom: -2.5px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 2.5px solid #a9a9a9;
    }

    .MMU {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 10px solid #a9a9a9;
    }

    .RNO {
      width: 12px;
      height: 10px;
      background: #a9a9a9;
      -moz-border-radius: 5px / 5px;
      -webkit-border-radius: 5px / 5px;
      border-radius: 5px / 5px;
    }

    .CEL {
      width: 10px;
      height: 10px;
      background: #a9a9a9;
    }

  }

  .node-type-container {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .node-type {
    // display: inline-block;

    span {
      width: 11px;
      height: 11px;
      display: inline-block;
      margin-right: 0.3rem;
      border-radius: 6px;
      position: relative;
      margin-bottom: -2px;
    }
  }

  // .node-type + .node-type {
  //   margin-left: 1rem;
  // }

  .node-type--protein-rna {
    span {
      background-color: rgba(103,58,183,0.7);
    }
  }

  .node-type--metabolite {
    span {
      background-color: rgba(33,150,245,0.7);
    }
  }

  .node-type--process {
    span {
      background-color: rgba(255,152,0,0.7);
    }
  }

  .node-type--pathology {
    span {
      background-color: rgba(136,34,85,0.7);
    }
  }

  // .node-type--protein {
  //   span {
  //     background-color: rgb(0,150,136);
  //   }
  // }

  .node-type--rna {
    span {
      background-color: rgba(121,85,72,0.7);
    }
  }

  .node-type--micro-rna {
    span {
      background-color: rgba(96,125,139,0.7);
    }
  }

  .node-type--complex-composite {
    span {
      background-color: rgba(139,195,74,0.7);
    }
  }
}
