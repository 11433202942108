.fa-ul  {
  margin-left: 1.5rem;
  font-size: $size-6 * 0.9;

  li {
    cursor: pointer;
    margin-bottom: 0.5rem;

    &.disabled {
      cursor: default;
      color: $grey-lighter;
    }
  }

  .fa-li.check,
  .fa-li.uncheck {
    display: none;
  }

  .fa-li.check.visible,
  .fa-li.uncheck.visible {
    display: block;
  }

  .fa-li.check.visible {
    color: $link;
  }
}
