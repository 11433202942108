footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;

  a {
    display: inline-block;
    color: $grey;
    font-size: $size-7;
    margin: 0 auto;
    background-color: $bg-color;
    padding: $gap/8 $gap/2;
  }
}
