  .compound-list, .similar-compound-list {
    margin-top: $gap;

    .compound {
      .compound__summary {
        display: flex;
        position: relative;

        .compound__delete {
          position: absolute;
          top: 0;
          right: 0;
          @extend .has-text-grey-light;
        }

        .compound__image {
          width: 15%;
          flex: 0 1 auto;

          img {
            max-width: 100%;
            height: auto;
            border: solid 1px $border-color;
          }
        }

        .compound__data {
          flex: 1 0 auto;
          word-wrap: break-word;
          padding: 0 0.5rem 0 1rem;

          .compound__similarity {
            margin-top: 1rem;
            font-size: 0.9rem;

            span {
              font-weight: bold;

              &.similarity--high {
                color: $color-success;
              }

              &.similarity--medium {
                color: $color-warning;
              }

              &.similarity--low {
                color: $color-danger;
              }
            }
          }
        }

        .compound__selection {
          flex: 0 1 auto;
          font-size: 0.9rem;
          align-self: center;

          > li {
            margin-bottom: 0;
          }

          &.compound__selection--no-data {
            font-style: italic;
            @extend .has-text-grey;
          }
        }

        &.compound__summary--analysis {
          .compound__data {
            width: 85%;
          }
        }

        &.compound__summary--selection {
          .compound__data {
            width: 65%;
          }

          .compound__selection {
            width: 20%;
          }
        }
      }

      .compound__display {
        width: 100%;
        text-align: center;
        margin-top: $gap/4;

        .compound__selection--no-data {
          font-style: italic;
          @extend .has-text-grey;
        }

        ul {
          width: auto;
          display: inline-block;
          margin: 0 auto;
          padding-left: 1.5rem;
        }
      }
    }

    .similar-compounds {
      margin-top: 1.5rem;

      .similarity-search-link {
        display: block;
        font-size: 0.9rem;
      }

      .similar-compounds-header {
        margin-bottom: 1rem;
        font-weight: bold;
        font-size: 0.9rem;
      }
    }
  }

  .compound-list {
    > .compound {
      padding: $gap/2;
      background-color: $white-ter;

      > .compound__summary .compound__data .compound__smiles {
          font-size: 0.85rem;
      }

      >.compound__summary .compound__data .compound__name {
         font-weight: bold;
      }
    }

    .compound + .compound {
      margin-top: $gap/2;
    }
  }
