.nav {
  position: fixed;
  top: $gap/2;
  right: $gap/2;
  z-index: $z-index-controls;

  background-color: $primary;
  padding: $gap/4 $gap/2;
  color: $white;
  display: flex;
  align-items: center;

  .icon {
    margin-right: $gap/4;
  }

  a {
    color: white;
    text-decoration: none;
  }
}
