.aop-details-container {
  @extend .modal__container;

  .aop-details-content {
    @extend .modal__content;
    width: 900px;
    min-height: 90vh;

    iframe {
      width: 100%;
      height: 500px;
    }

    .aop-wiki-link {
      margin-top: $gap/2;
    }
  }
}
