.tippy-popper[data-html] {
  width: 600px;
}

.tippy-popper {
  transition-duration: 50ms;
}

.tippy-tooltip.network-theme {
  border: 1px solid $border-color;
  color: $light;
  box-shadow: $box-shadow;
  background-color: #fff;
  padding: 1rem;

  .tippy-content {
    color: $dark;
    text-align: left;
    font-size: $size-normal;
    max-height: 50vh;
    overflow: scroll;

    h3 {
      font-size: $size-medium;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .compound-value {
      color: $color-danger;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  hr {
    margin: 0.8rem 0;
  }

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .tippy-backdrop {
    background-color: #fff;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}

.tippy-tooltip.light-theme[data-animatefill] {
  background-color: transparent;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.network-theme .tippy-arrow,
.tippy-popper[x-placement^=top] .tippy-tooltip.network-theme .tippy-arrow {
  background-color: white;
  border-bottom: 1px solid transparent;
  border-right: 1px solid $border-color;
  border-left: 1px solid transparent;
  border-top: 1px solid $border-color;
  height: 10px;
  width: 10px;
  transform-origin: 10px 10px;
  margin: 0 10px;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.network-theme .tippy-arrow {
  transform: rotate(-45deg);
  top: -10px;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.network-theme .tippy-arrow {
  transform: rotate(135deg);
  bottom: 0px;
}
