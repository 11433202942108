$family-primary: 'Lato', Helvetica, Arial, sans-serif;

$z-index-controls: 1000;
$z-index-overlay: 2000;

$border-color: $grey-lighter;
$bg-color: $white-ter;
$box-shadow: 0 0 5px 2px rgba(154,161,177,.15),0 4px 20px -8px rgba(36,40,47,.25),0 4px 4px -2px rgba(91,94,105,.15);

$color-danger: #d32f2f;
$color-warning: #f57c00;
$color-success: #43a047;
